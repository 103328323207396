<template>
  <div class="container">
    <div class="form">
      <div class="row start" style="margin: 12px 0">
        <div class="label must">问题名称</div>
        <van-field v-model="form.title" rows="5" autosize type="textarea" />
      </div>

      <div class="row">
        <div class="label must">处理人</div>
        <div class="item">{{ dealName }}</div>
      </div>
     
    </div>

    <van-button type="info" block @click="subForm">提交</van-button>
  </div>
</template>
<script>
import { ask } from '@/api/eventApi'
export default {
  data() {
    return {
        form: {
          title: '',
          content: '',
        },
        dealName: '',
    }
  },
  created() {
    this.form.dealUser = this.$route.query.id
    this.dealName = this.$route.query.name
  },
  mounted() {},
  methods: {
    onConfirm(val) { 
      this.form.dealUser = val.dealUser
      this.form.dealUserName = val.name
      this.showPicker = false
    },
    subForm() {
      if(!this.form.title || !this.form.dealUser) {
        return this.$toast({
            type: 'warning',
            message: '请完善必填项',
            duration: 1000,
          })
      }
      this.$store.commit('SET_IS_LOADING', true)
      ask({
        title: this.form.title,
        category: 1,
        type: 3,
        content: this.form.content,
        status: 0,
        dealUser: this.form.dealUser,
        openid: this.$store.getters.openId 
      }).then(res => {
        this.$store.commit('SET_IS_LOADING', false)
        if(res.code == 200) {
          this.form = {
            title: '',
            content: '',
          }
          this.$toast({
            type: 'success',
            message: '提问成功，有专家回答后会第一时间通知您',
            duration: 1000,
            onClose: () => {
              this.$router.go(-2)
            }
          })
          
        } else {
          this.$toast({
            type: 'error',
            message: res.msg,
            duration: 1000,
          })
        }
      })
    }
  },
}
</script>
<style scoped lang="scss">
// @import '@/assets/css/form.css';
.container {
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  font-size: 14px;
}
.row {
  width: 100%;
  min-height: 40px;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 12px;
  &.row2 {
    align-items: center;
    .label {
    width: 65px;
    flex-shrink: 0;
    }
    .van-field {
      padding: 2px 4px;
    }
  }
  &.col {
    flex-direction: column;
    align-items: start;
    .van-field {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  /* height: 100px; */
}
    .label {
      margin-bottom: 10px;
    }
  }
  .label {
    width: 65px;
    flex-shrink: 0;
  }
  .label.must {
    position: relative;
  }
  .label.must::after {
    content: '*';
    color: #e10602;
    font-size: 18px;
    position: absolute;
    left: -8px;
    top: 1px;
  }
}
.form {
  min-height: calc(100vh - 24px - 46px - 56px);
}
.row.start {
  align-items: start;
}
::v-deep .row .van-uploader__upload {
  border: 1px solid #eeeeee;
  background-color: #fff;
  border-radius: 5px;
}
.item {
  width: 100%;
  height: 28px;
  line-height: 28px;
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 0 5px;
}
.van-field {
  border: 1px solid #eeeeee;
  padding: 5px;
  border-radius: 5px;
}
</style>
